import { Avatar } from "@mui/material";
import React from "react";
import { s3baseUrl } from "../../config/config";
import { Icon } from "@iconify/react";
import { useChat } from "../../Hooks/ChatContext";
import { string_avatar } from "../../utils/constant";
import ChatMessages from "./ChatMessages";

function ChatRight() {
  const { selectedChat, handleCloseChat, selectedChatUser } = useChat();
  const receiver_profile = selectedChatUser;

  return (
    <div className="chat-right-wrapper">
      <div className="chat-right-head p-1 d-flex justify-content-between border-bottom">
        <div className="d-flex">
          {selectedChat && (
            <>
              <div className="user-profile">
                <Avatar
                  src={s3baseUrl + receiver_profile?.profileImage}
                  style={{ height: 45, width: 45 }}
                >
                  {string_avatar(receiver_profile?.name[0])}
                </Avatar>
                <div
                  className={receiver_profile?.isOnline ? "online" : "offline"}
                ></div>
              </div>
              <div className="chat-profile-data">
                <div className="chat-profile-name ps-2 text-capitalize">
                  {receiver_profile?.name}
                </div>
              </div>
            </>
          )}
        </div>
        {receiver_profile && (
          <div className="me-2" onClick={handleCloseChat}>
            <Icon
              fontSize={18}
              className="chat-box-cross-icon"
              icon="material-symbols:close"
            />
          </div>
        )}
      </div>
      <ChatMessages is_popup={false} />
    </div>
  );
}

export default ChatRight;
