import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Avatar, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { _member_list_with_search } from "src/DAL/Chat/Chat";
import { string_avatar } from "src/utils/constant";
import { s3baseUrl } from "src/config/config";

export default function NewChatUsers({ handleAddChat, onCloseDrawer }) {
  const { enqueueSnackbar } = useSnackbar();
  const [chatUsersList, setChatUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    search_text: "",
  });

  const getChatUsersList = async () => {
    const result = await _member_list_with_search(state.search_text);
    if (result.code === 200) {
      let new_coaches_list = [];
      if (result?.coaches?.data.length > 0) {
        new_coaches_list = result?.coaches?.data.map((coach) => {
          let full_name = coach.first_name + " " + coach.last_name + " (Coach)";
          return {
            ...coach,
            full_name: full_name,
          };
        });
      }
      let new_members_list = [];
      if (result?.members?.data.length > 0) {
        new_members_list = result?.members?.data.map((member) => {
          let full_name =
            member.first_name + " " + member.last_name + " (Member)";
          return {
            ...member,
            full_name: full_name,
          };
        });
      }

      let final_list = [...new_coaches_list, ...new_members_list];
      setChatUsersList(final_list);
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    getChatUsersList();
  }, [state]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="new-message-search-box mt-3">
            <Icon
              fontSize={20}
              className="new-message-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="new-message-search-input"
              type="text"
              placeholder="Search"
              name="search_text"
              value={state.search_text}
              onChange={handleChange}
            />

            <div className="new-message-users-list mt-4">
              <h3>Users List</h3>
              {chatUsersList.map((user, index) => {
                return (
                  <div
                    className="message-user-box p-2 mt-3"
                    onClick={() => {
                      handleAddChat(user._id, onCloseDrawer);
                    }}
                    key={index}
                  >
                    <div className="d-flex align-items-center pointer">
                      <div className="user-profile">
                        <Avatar
                          src={s3baseUrl + user?.image?.thumbnail_1}
                          className="message-user-avatar"
                          alt="photoURL"
                          style={{ width: 33, height: 33 }}
                        >
                          {string_avatar(user.full_name)}
                        </Avatar>
                        <div
                          className={user.is_online ? "online" : "offline"}
                        ></div>
                      </div>
                      <div className="ms-2">
                        <p className="mb-0"> {user.full_name}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
