import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  Vault,
  Profile,
  Pods,
  Quotes,
  Memories,
  MemoriesDetails,
  DailyActivities,
  Calender,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  ClientVideos,
  AdminVideos,
  AddAdminVideos,
  AdminVideoDetail,
  AddClientVideos,
  ClientVideoDetail,
  EditAdminVideos,
  EditClientVideos,
} from "./pages/index";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import AddProgramme from "./pages/Programmes/AddProgramme";
import EditProgramme from "./pages/Programmes/EditProgramme";
import AddLesson from "./pages/Programmes/AddLesson";
import EditLesson from "./pages/Programmes/EditLesson";
import LessonRecordingListing from "./pages/LessonRecording/LessonRecordingListing";
import LessonRecordingDetail from "./pages/LessonRecording/LessonRecordingDetail";
import AddLessonRecording from "./pages/LessonRecording/AddLessonRecording";
import EditLessonRecording from "./pages/LessonRecording/EditLessonRecording";
import { ResourcesCard } from "./pages/LessonRecording/ResourcesCard";
import AddLessonDocument, {
  AddLessonDecoument,
} from "./pages/LessonRecording/AddLessonDocument";
import EditLessonDocument from "./pages/LessonRecording/EditLessonDocument";
import { ProgrammeDocumentList } from "./pages/Programmes/ProgrammeDocumentList";
import AddProgrammeDocument from "./pages/Programmes/AddProgramDocument";
import EditProgrammeDocument from "./pages/Programmes/EditProgrammeDocument";
import { SectionList } from "./pages/section/SectionList";
import ProgrammeReviews from "./pages/ProgramReviews/ProgrammeReviews";
import AddProgrammeReview from "./pages/ProgramReviews/AddProgramReview";
import EditProgrammeReview from "./pages/ProgramReviews/EditProgramReview";
import ProgrammeSectionList from "./pages/Sections/SectionList";
import LockedProgram from "./pages/Programmes/LockedProgramInfo";
import AddProgrammeSection from "./pages/Sections/AddProgrammeSection";
import EditProgrammeSection from "./pages/Sections/EditProgrammeSection";
import ProgrammeAutomatedGroups from "./pages/AutomatedGroups/ProgrammeAutomatedGroup";
import AddProgrammeAutoGroup from "./pages/AutomatedGroups/AddAutoGroup";
import EditProgrammeAutoGroup from "./pages/AutomatedGroups/EditAutoGroup";
import MembersList from "./pages/Members/MembersList";
import AddMember from "./pages/Members/AddMember";
import EditMember from "./pages/Members/EditMember";
import ConsultantList from "./pages/consultant/consultantList";
import AddConsultant from "./pages/consultant/AddCoach";
import EditConsultant from "./pages/consultant/EditCoach";
import ChangePassword from "./pages/Members/ChangePassword";
import ChangeConsultantPassword from "./pages/consultant/Changepassword";
import ConsultantRecordings from "./pages/consultant/ConsultantRecordings";
import ConsultantRecordingDetail from "./pages/consultant/RecordingDetail";
import MemberProfile from "./pages/Members/MemberProfile";
import MemberAllTransaction from "./pages/Members/AllTransactions";
import ViewBillingList from "./pages/Members/ViewBilling";
import BillingRequestDetail from "./pages/Members/BillingDetail";
import AddPaymentRequest from "./pages/Members/AddPaymentRequest";
import ManageProgrammeAccess from "./pages/Members/ManageProgrammeAccess";
import ConsultantGroupsList from "./pages/consultant/ConsultantGroups";
import ConsultantGroupDetail from "./pages/consultant/ConsultantGroupDetail";
import ConsultantPodsList from "./pages/consultant/PodsListing";
import MembersListConsultant from "./pages/consultant/MemberList";
import ConsultantGoalStatementList from "./pages/consultant/ConsultantGoalStatement";
import ConsultantPodsDetail from "./pages/consultant/PodsDetail";
import MemberGroupsList from "./pages/Members/MembersGroupList";
import MemberPersonalNote from "./pages/Members/MemberPersonalNote";
import MemberGoalstatementList from "./pages/Members/MemberGoalstatementList";
import PodsListMemberProfile from "./pages/Members/PodsListMemberProfile";
import ConsultantGoalstatementDetail from "./pages/consultant/ConsultantGoalstatementDetail";
import AddQuestions from "./pages/GoalStatement/AddQuestion";
import DetailQuestion from "./pages/GoalStatement/DetailQuestion";
import EditQuestions from "./pages/GoalStatement/EditQuestion";
import { QuestionsReply } from "./pages/Members/QuestionsReply";
import { AnswerHistory } from "./pages/Members/AnswerHistory";
import NinetyDayQuestion from "./pages/90DayQuestion/90DayQuestion";
import Recordings from "./pages/Recordings/Recordings";
import AddRecordings from "./pages/Recordings/AddRecordings";
import EditRecording from "./pages/Recordings/EditRecording";
import VaultCategory from "./pages/Vault/Vault";
import AddVaultCategory from "./pages/Vault/AddVaultCategory";
import EditVaultCategory from "./pages/Vault/EditVaultCategory";
import ProgrammeRecordingDetail from "./pages/Recordings/RecordingDetail";
import StudySession from "./pages/studySession/StudySession";
import AddStudySession from "./pages/studySession/AddStudySession";
import EditStudySession from "./pages/studySession/EditStudySession";
import StudySessionRecording from "./pages/studySession/StudySessionRecordings";
import AddStudySessionRecording from "./pages/studySession/AddStudySessionRecording";
import EditStudySessionRecording from "./pages/studySession/EditStudySessionRecording";
import QuotesList from "./pages/Quotes/Quotes";
import AddQuotes from "./pages/Quotes/AddQuotes";
import EditQuotes from "./pages/Quotes/EditQuotes";
import PaymentTransactionsList from "./pages/Payments/TransactionsList";
import StripeProductsList from "./pages/Payments/StripeProductsList";
import AddStripeProduct from "./pages/Payments/AddStripeProduct";
import EditStripeProduct from "./pages/Payments/EditStripeProduct";
import PaymentTemplateList from "./pages/PaymentTemplate/PaymentTemplateList";
import AddPaymentTemplate from "./pages/PaymentTemplate/AddPaymentTemplate";
import EditPaymentTemplate from "./pages/PaymentTemplate/EditPaymentTemplate";
import PaymentRequestList from "./pages/PaymentRequest/PaymentRequestList";
import AddPaymentRequests from "./pages/PaymentRequest/AddPaymentRequests";
import EditPaymentRequests from "./pages/PaymentRequest/EditPaymentRequests";
import PaymentRequestInfo from "./pages/PaymentRequest/PaymentRequestInfo";
import CalendarGroupDetail from "./pages/CalendarGroups/CalendarGroupDetail";
import CalendarPodsList from "./pages/CalendarPods/CalendarPodsList";
import AddPods from "./pages/CalendarPods/AddPods";
import AddCalendarPods from "./pages/CalendarPods/AddPods";
import EditCalendarPods from "./pages/CalendarPods/EditPods";
import CalendarPodsDetail from "./pages/CalendarPods/CalendarPodsDetail";
import AddAutomatedRoomUser from "./pages/CalendarPods/AddRoomUser";
import EditAutomatedRoomUser from "./pages/CalendarPods/EditRoomUser";
import Calenders from "./pages/Calender/Calender";
import UpdateGeneralSetting from "./pages/ClientSetting/GeneralSetting";
import ClientDashboardSetting from "./pages/ClientSetting/ClientDashboard";
import UpdateContentSetting from "./pages/ClientSetting/ContentSetting";
import UpdateConsultantGeneralSetting from "./pages/ConsultantPortal/GeneralSetting";
import AddSendGrid from "./pages/sendGrid/SendGrid";
import AddCompaignSetting from "./pages/SiteSetting/CompaingSetting";
import AddStripeSetting from "./pages/SiteSetting/StripeSetting";
import EmailSetting from "./pages/SiteSetting/EmailSetting";
import DefaultSetting from "./pages/SiteSetting/DefaultSetting";
import EmailTemplates from "./pages/SiteSetting/EmailTemplates";
import EditEmailTemplate from "./pages/SiteSetting/EditEmailTemplate";
import { EmailTemplatePreview } from "./pages/SiteSetting/EmailTemplatePreview";
import ToDoTracker from "./pages/Members/ToDoTracker";
import WebsitePages from "./pages/ManageWebiste/Pages";
import AddPages from "./pages/ManageWebiste/AddPages";
import EditPages from "./pages/ManageWebiste/EditPages";
import UpdatePageContent from "./pages/WebPages/WebsitePages/UpdatePageContent";
import PaymentPlansList from "./pages/PaymentPlans/PaymentPlansList";
import AddPaymentPlan from "./pages/PaymentPlans/AddpaymentPlan";
import EditPaymentPlan from "./pages/PaymentPlans/EditpaymentPlan";
import PodCastList from "./pages/WebsitePodCasts/PodCastList";
import GeneralPaymentPlansList from "./pages/PaymentPlanGeneral/PaymentPlansList";
import EditProfile from "./pages/EditProfile/EditProfile";
import ImportMembers from "./pages/Members/ImportMember";
import CommunityList from "./pages/Community/CommunityList";
import AddCommunity from "./pages/Community/AddCommunity";
import EditCommunity from "./pages/Community/EditCommunity";
import SeriesVideosList from "./pages/SeriesVideos/SeriesVideosList";
import AddSeriesVideos from "./pages/SeriesVideos/AddSeriesVideos";
import EditSeriesVideos from "./pages/SeriesVideos/EditSeriesVideos";
import FeedsList from "./pages/Feeds/FeedsList";
import AddFeeds from "./pages/Feeds/AddFeed";
import EditFeeds from "./pages/Feeds/EditFeed";
import FeedSetting from "./pages/Feeds/FeedSetting";
import SeriesCategoryList from "./pages/seriesCategories/SeriesCategoryList";
import AddSeriesCategory from "./pages/seriesCategories/AddSeriesCategory";
import EditSeriesCategory from "./pages/seriesCategories/EditSeriesCategory";
import ManageRewardAccess from "./pages/Members/ManageRewardAccess";
import MemoryHistory from "./pages/Members/MemberHistory";
import AddGeneralPaymentPlan from "./pages/PaymentPlanGeneral/AddGeneralpaymentPlan";
import EditGeneralPaymentPlan from "./pages/PaymentPlanGeneral/EditGeneralpaymentPlan";
import ProgrammeCategoriesList from "./pages/ProgrammeCategories/ProgrammeCategoriesList";
// import BusinessStrategyList from "./pages/BusinessStrategy/BusinessStrategyList";
// import AddBusinessStrategy from "./pages/BusinessStrategy/AddBusinessStrategy";
// import EditBusinessStrategy from "./pages/BusinessStrategy/EditBusinessStrategy";
// import AddWebPageTestimonial from "./pages/WebPageTestimonials/AddWebPageTestimonial";
// import EditWebPageTestimonial from "./pages/WebPageTestimonials/EditWebPageTestimonial";
import AddWebPageEvent from "./pages/WebPageEvents/AddWebPageEvent";
import EditWebPageEvent from "./pages/WebPageEvents/EditWebPageEvent";
import WebPageEvents from "./pages/WebPageEvents/WebPageEvents";
import EditDynamiteEventMember from "./pages/DynamiteEvents/EditDynamiteEventMember";
import WebsiteProgrammesList from "./pages/WebPages/WebsiteProgrammes/WebsiteProgrammesList";
import AddWebsiteProgramme from "./pages/WebPages/WebsiteProgrammes/AddOrUpdateProgramme";
import EditWebsiteProgramme from "./pages/WebsiteProgrammes/EditWebsiteProgramme";
import EditProgrammeCategories from "./pages/ProgrammeCategories/EditProgrammeCategoies";
import AddProgrammeCategories from "./pages/ProgrammeCategories/AddProgrammeCategoies";
import ChartJs from "./components/charts/ChartToDo";
import EventSubscribers from "./pages/Members/EventSubscriber";
import { QuestionSurvey } from "./pages/QuestionSurvey/QuestionSurvey";
import PodcastsList from "./pages/Podcasts/PodCastList";
import AddPodCast from "./pages/Podcasts/AddPodCast";
import EditPodCast from "./pages/Podcasts/EditPodCast";
// import EditGeneralPaymentPlan from "./pages/PaymentPlanGeneral/EditGeneralpaymentPlan";
import SalePageTestimonials from "./pages/salePageTestimonial/SalePageTestimonial";
import AddGeneralTestimonial from "./pages/salePageTestimonial/AddGeneralTestimonial";
import EditSalePageTestimonial from "./pages/salePageTestimonial/EditGeneralTestimonial";
// import ProgrammeCategoriesList from "./pages/ProgrammeCategories/ProgrammeCategoriesList";
// import WebsiteProgrammesList from "./pages/WebsiteProgrammes/WebsiteProgrammesList";
// import AddWebsiteProgramme from "./pages/WebsiteProgrammes/AddWebsiteProgramme";
// import EditWebsiteProgramme from "./pages/WebsiteProgrammes/EditWebsiteProgramme";
// import AddProgrammeCategories from "./pages/ProgrammeCategories/AddProgrammeCategoies";
// import EditProgrammeCategories from "./pages/ProgrammeCategories/EditProgrammeCategoies";
import GeneralFaqList from "./pages/FAQ/GeneralFaqList";
import AddFAQ from "./pages/FAQ/AddFaq";
import EditFAQ from "./pages/FAQ/EditFaq";
import GeneralTeamList from "./pages/Team/TeamList";
import AddTeam from "./pages/Team/AddTeam";
import EditTeam from "./pages/Team/EditTeam";
import GeneralPageTestimonials from "./pages/salePageTestimonial/GeneralTestimonial";
import MediaSectionList from "./pages/MediaSection/MediaBrandsList";
import AddMediaSection from "./pages/MediaSection/AddMedia";
import EditMediaSection from "./pages/MediaSection/EditMedia";
import SuccessProductList from "./pages/SuccessProduct/SuccessProductList";
import AddSuccessProduct from "./pages/SuccessProduct/AddSuccessProduct";
import EditSuccessProduct from "./pages/SuccessProduct/EditSuccessProduct";
import WhatsIncludeList from "./pages/WhatsInclude/WhatsIncludeList";
import AddWhatsInclude from "./pages/WhatsInclude/AddWhatsInclude";
import EditWhatsInclude from "./pages/WhatsInclude/EditWhatsInclude";
import MediaSections from "./pages/MediaSection/MediaSectionTabs";
import SocialSharingCenter from "./pages/ManageWebiste/soicalSharingCenter";
import TemplatePages from "./pages/WebPages/TemplatePages/TemplatePages";
import SocialSharingSetting from "./pages/WebPages/TemplatePages/SoicalSharingSetting";
import PageSubscribersList from "./pages/WebPages/TemplatePages/PageSubscribersList";
import AddOrUpdatePage from "./pages/WebPages/WebsitePages/AddOrUpdatePage";
import UpdateThanksContent from "./pages/WebPages/WebsitePages/UpdateThanksContent";
import AddOrUpdateWebPageTestimonial from "./pages/WebPages/WebPageTestimonials/AddOrUpdateWebPageTestimonial";
import WebPageFaqs from "./pages/WebPages/WebPageFaqs/WebPageFaqs";
import AddOrUpdateWebPageFaq from "./pages/WebPages/WebPageFaqs/AddOrUpdateWebPageFaq";
import MenuList from "./pages/WebPages/WebsitePages/MenuItems/MenuList";
import { MenuItems } from "./pages/WebPages/WebsitePages/MenuItems/MenuItems";
import WebPageTestimonials from "./pages/WebPages/WebPageTestimonials/WebPageTestimonials";
import WebPagePaymentPlans from "./pages/WebPages/WebPagePaymentPlans/WebPagePaymentPlans";
import AddOrUpdatePaymentPlan from "./pages/WebPages/WebPagePaymentPlans/AddOrUpdatePaymentPlan";
import BusinessStrategyList from "./pages/WebPages/BusinessStrategy/BusinessStrategyList";
import AddOrUpdateBusinessStrategy from "./pages/WebPages/BusinessStrategy/AddOrUpdateBusinessStrategy";
import AddBusinessStrategy from "./pages/WebPages/BusinessStrategy/AddOrUpdateBusinessStrategy";
import SuccessScheduleList from "./pages/WebPages/SuccessSchedule/successScheduleList";
import AddOrUpdateSuccessSchedule from "./pages/WebPages/SuccessSchedule/AddOrUpdateSuccessSchedule";
import AddOrUpdateProgramme from "./pages/WebPages/WebsiteProgrammes/AddOrUpdateProgramme";
import SiteSetting from "./pages/WebPages/WebPageFaqs/SiteSetting";
import UnpublishSetting from "./pages/WebPages/WebsitePages/UnpublishSetting";
import AddDynamiteEventMember from "./pages/DynamiteEventsNew/EventMembers/AddDynamiteEventMember";
import AddOrUpdateGeneralEvents from "./pages/DynamiteEventsNew/GeneralEvents/AddOrUpdateGeneralEvents";
import GeneralEvents from "./pages/DynamiteEventsNew/GeneralEvents/GeneralEvents";
import BannerSliderList from "./pages/WebPages/BannerSlider/BannerSliderList";
import AddBannerSlider from "./pages/WebPages/BannerSlider/AddBannerSlider";
import InvoiceSetting from "./components/InvoiceSetting/InvoiceSetting";
import ProgrammeBulkAccess from "./components/ProgrammBulkAccess/ProgrammeBulkAccess";
import SubscriptionList from "./pages/SubscriptionList/SubscriptionList";
import ProgrammeMembers from "./pages/Programmes/ProgrammeMembers";
import MemberSubscriptions from "./pages/Members/MemberSubscriptions/MemberSubscriptions";
import PagePlanSubscribersList from "./pages/WebPages/WebPagePaymentPlans/PagePlanSubscribersList";
import ProgrammeAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/ProgrammeAccessPaymentPlan";
import EventAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/EventAccessPaymentPlan";
import ExercisListing from "./pages/Exercises/ExercisListing";
import AddExercise from "./pages/Exercises/AddExercise";
import ExerciseDetail from "./pages/Exercises/ExerciseDetail";
import AddCoach from "./pages/consultant/AddCoach";
import EditCoach from "./pages/consultant/EditCoach";
import ChangeCoachPassword from "./pages/consultant/Changepassword";
import EditExercise from "./pages/Exercises/EditExercise";
import ExercisListingType from "./pages/ExercisesType/ExercisListingType";
import AddExerciseType from "./pages/ExercisesType/AddExerciseType";
import EditExerciseType from "./pages/ExercisesType/EditExerciseType";
import WorkoutListingType from "./pages/WorkoutType/WorkoutListingType";
import AddWorkoutType from "./pages/WorkoutType/AddWorkoutType";
import EditWorkoutType from "./pages/WorkoutType/EditWorkoutType";

import WorkoutDetail from "./pages/Workout/WorkoutDetail";
import WorkoutListing from "./pages/Workout/WorkoutListingnew";

import FormTemplates from "./pages/UserFormTemplate/FormTemplates";
import AddFormTemplate from "./pages/UserFormTemplate/addFormTemplate";
import EditFormTemplate from "./pages/UserFormTemplate/editFormTemplate";
import TemplatePreview from "./pages/UserFormTemplate/templatePreview";
import UserForm from "./pages/UserForms/UserForm";
import AddForm from "./pages/UserForms/AddForm";
import EditForm from "./pages/UserForms/EditForm";
import FormPreview from "./pages/UserFormTemplate/templatePreview";
import DetailsFormInformation from "./pages/UserForms/DetailsFormInformation";
import DetailsUserFormInformation from "./pages/Members/UserForms/DetailsUserFormInformation";
import ListUserForm from "./pages/Members/UserForms/ListUserForm";
import ListCoachForm from "./pages/consultant/CoachForms/ListCoachForm";
import DetailsCoachFormInformation from "./pages/consultant/CoachForms/DetailsCoachFormInformation";
import DynamicTable from "./pages/Calender/component/FixedCalender";
import AddWorkout from "./pages/Workout/AddWorkoutNew";
import EditWorkout from "./pages/Workout/EditWorkoutNew";
import MemberCalendar from "./pages/Members/MemberCalendar";
import WorkoutDisplay from "./pages/Banner/WorkoutDisplay";
import ExerciseTages from "./pages/ExercisesTages/ExerciseTages";
import ProgramUsersForm from "./pages/Programmes/UserForms";
import Feed from "./pages/CommunityFeeds/Feed";
import Groups from "./pages/Groups/Groups";
import AddGroups from "./pages/Groups/AddGroups";
import EditGroup from "./pages/Groups/EditGroup";
import GroupMembers from "./pages/Groups/GroupMembers";
import GroupsFeedsDetail from "./pages/CommunityFeeds/GroupsFeedsDetail";
import PendingPosts from "./pages/CommunityFeeds/PendingPosts";
import RejectedPosts from "./pages/CommunityFeeds/RejectedPosts";
import MyPosts from "./pages/CommunityFeeds/MyPosts";
import UserFormAgainstProgram from "./pages/UserForms/Components/UserFormAgainstProgram";
import PostDetail from "./pages/CommunityFeeds/PostDetail";
import FeedDetailPage from "./pages/CommunityFeeds/FeedDetailPage";
import FoodMattersListingType from "./pages/FoodMattersType/FoodMattersListingType";
import AddFoodMattersType from "./pages/FoodMattersType/AddFoodMattersType";
import EditFoodMattersType from "./pages/FoodMattersType/EditFoodMattersType";
import MindSetListingType from "./pages/MindSetType/MindSetListingType";
import AddMindSetType from "./pages/MindSetType/AddMindSetType";
import EditMindSetType from "./pages/MindSetType/EditMindSetType";
import FoodMattersListing from "./pages/FoodMattersLibrary/FoodMattersListing";
import AddfoodMatters from "./pages/FoodMattersLibrary/AddfoodMatters";
import EditFoodMatters from "./pages/FoodMattersLibrary/EditFoodMatters";
import MindSetListing from "./pages/MindSetLibrary/MindSetListing";
import AddMindset from "./pages/MindSetLibrary/AddMindset";
import EditMindset from "./pages/MindSetLibrary/EditMindset";
import RecentPointsTable from "./pages/Members/RecentPointsTable";
import AddProgrammeTemplate from "./pages/ProgrammesTemplates/AddProgrammeTemplate";
import EditProgrammeTemplate from "./pages/ProgrammesTemplates/EditProgrammeTemplate";
import ProgrammesTemplate from "./pages/ProgrammesTemplates/ProgrammesTemplate";
import FixedCalenderForTemplates from "./pages/Calender/component/FixedCalenderForTemplates";
import AdminVideosCatagories from "./pages/HelpingVideos/AdminVideosCatagories";
import AddAdminCategory from "./pages/HelpingVideos/AddAdminCategory";
import EditAdminVideosCategory from "./pages/HelpingVideos/EditAdminVideosCategory";
import AddOrUpdateWebPagePlanCategory from "./pages/WebPages/WebPagePlanCategory/AddOrUpdateWebPagePlanCategory";
import WebPagePlanCategory from "./pages/WebPages/WebPagePlanCategory/WebPagePlanCategory";
import WebPageManagePlanCategory from "./pages/WebPages/WebPagePlanCategory/WebPageManagePlanCategory";
import VideoSetListing from "./pages/VideoLibrary/VideoSetListing";
import AddVideoSet from "./pages/VideoLibrary/AddVideoSet";
import EditVideoSet from "./pages/VideoLibrary/EditVideoSet";
import VideoSetListingType from "./pages/VideosType/VideoSetListingType";
import AddVideoSetType from "./pages/VideosType/AddVideoSetType";
import EditVideoSetType from "./pages/VideosType/EditVideoSetType";
import Chat from "./pages/Chat/Chat";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/programmes"> </Navigate>;
    // return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route
          path="/user_form-templates/add-user_form-templates"
          element={<AddFormTemplate />}
        />{" "}
        <Route
          path="/user_form-templates/edit-user_form-templates/:id"
          element={<EditFormTemplate />}
        />
        <Route
          path="/user_form-templates/preview-user_form-templates/:id"
          element={<TemplatePreview />}
        />
        <Route path="/user_form-templates" element={<FormTemplates />} />
        <Route path="/forms" element={<UserForm />} />
        <Route
          path="/forms/:fId/template/:tId/program/:id"
          element={<UserFormAgainstProgram />}
        />
        <Route
          path="/programmes/forms/:fId/template/:tId/program/:id"
          element={<UserFormAgainstProgram />}
        />
        <Route path="/forms-send-forms" element={<AddForm />} />
        <Route
          path="/forms-preview/:slug"
          element={<DetailsFormInformation />}
        />
        <Route
          path="/programmes/forms-preview/:slug"
          element={<DetailsFormInformation />}
        />
        <Route path="/forms-edit-forms/:slug" element={<EditForm />} />
        <Route path="/exercise" element={<ExercisListing />} />
        <Route path="/exercise-add" element={<AddExercise />} />
        <Route path="/exercise-edit/:slug" element={<EditExercise />} />
        <Route path="/exercise-details/:slug" element={<ExerciseDetail />} />
        <Route path="/type-exercise" element={<ExercisListingType />} />
        <Route path="/tages-exercise" element={<ExerciseTages />} />
        <Route path="/type-workout" element={<WorkoutListingType />} />
        <Route path="/workout" element={<WorkoutListing />} />
        <Route path="/workout-add" element={<AddWorkout />} />
        <Route path="/workout-edit/:slug" element={<EditWorkout />} />
        <Route path="/workout-details/:slug" element={<WorkoutDetail />} />
        <Route path="/type-exercise-add" element={<AddExerciseType />} />
        <Route path="/type-workout-add" element={<AddWorkoutType />} />
        <Route path="/food_matters" element={<FoodMattersListing />} />
        <Route path="/food_matters-add" element={<AddfoodMatters />} />
        <Route path="/food_matters-edit/:slug" element={<EditFoodMatters />} />
        <Route path="/food_matters_type" element={<FoodMattersListingType />} />
        <Route path="/food_matters_type-add" element={<AddFoodMattersType />} />
        <Route
          path="/food_matters_type-edit/:slug"
          element={<EditFoodMattersType />}
        />
        <Route path="/mindset" element={<MindSetListing />} />
        <Route path="/mindset-add" element={<AddMindset />} />
        <Route path="/mindset-edit/:slug" element={<EditMindset />} />
        <Route path="/mindset_type" element={<MindSetListingType />} />
        <Route path="/mindset_type-add" element={<AddMindSetType />} />
        <Route path="/mindset_type-edit/:slug" element={<EditMindSetType />} />
        <Route path="/videoset" element={<VideoSetListing />} />
        <Route path="/videoset-add" element={<AddVideoSet />} />
        <Route path="/videoset-edit/:slug" element={<EditVideoSet />} />
        <Route path="/videoset_type" element={<VideoSetListingType />} />
        <Route path="/videoset_type-add" element={<AddVideoSetType />} />
        <Route
          path="/videoset_type-edit/:slug"
          element={<EditVideoSetType />}
        />
        <Route
          path="/type-exercise-edit/:slug"
          element={<EditExerciseType />}
        />
        <Route path="/type-workout-edit/:slug" element={<EditWorkoutType />} />
        <Route path="/programmes" element={<Programmes />} />
        <Route
          path="/programmes/programmes-detail/:id"
          element={<ProgrammesDetail />}
        />
        <Route
          path="/programmes/programmes-form/:id"
          element={<ProgramUsersForm />}
        />
        <Route
          path="/programmes/programmes-form/forms-preview/:slug"
          element={<DetailsFormInformation />}
        />
        <Route path="/programmes/members/:id" element={<ProgrammeMembers />} />
        <Route path="/programmes/add-programme" element={<AddProgramme />} />
        <Route
          path="/programmes/edit-programme/:programme_slug"
          element={<EditProgramme />}
        />
        <Route path="/programme_templats" element={<ProgrammesTemplate />} />
        <Route
          path="/programme_templats/add-programme_templats"
          element={<AddProgrammeTemplate />}
        />
        <Route
          path="/programme_templats/programme_templats-detail/:id"
          element={<ProgrammesDetail />}
        />
        <Route
          path="/programme_templats-limited-calendar/:slug"
          element={<FixedCalenderForTemplates />}
        />
        <Route
          path="/programme_templats/edit-programme_templats/:programme_slug"
          element={<EditProgrammeTemplate />}
        />
        <Route
          path="/programme_templats-calendar/:slug"
          element={<Calenders />}
        />
        <Route
          path="/programmes/lessons-listing/:slug"
          element={<LessonListing />}
        />
        <Route
          path="/programmes/lessons-detail/:id"
          element={<LessonsDetail />}
        />
        <Route path="/programmes/add-lessons" element={<AddLesson />} />
        <Route path="/programmes/edit-lessons/:id" element={<EditLesson />} />
        <Route
          path="/programmes/lessons/recordings/:slug"
          element={<LessonRecordingListing />}
        />
        <Route
          path="/programmes/lesson-recording-detail/:slug"
          element={<LessonRecordingDetail />}
        />
        <Route
          path="/programmes/add-lesson-recording/:slug"
          element={<AddLessonRecording />}
        />
        <Route
          path="/programmes/edit-lessons-recording/:slug"
          element={<EditLessonRecording />}
        />
        <Route
          path="/programmes/lesson/lesson-doc/:slug"
          element={<ResourcesCard />}
        />
        <Route
          path="/programmes/lessons/add-lesson-document/:slug"
          element={<AddLessonDocument />}
        />
        <Route
          path="/programmes/lessons/edit-lesson-document/:slug"
          element={<EditLessonDocument />}
        />
        <Route path="/programmes/:type/:id" element={<ProgrammeBulkAccess />} />
        {/* programms Doc routes */}
        {/* PodCastList */}
        <Route path="/pod-casts" element={<PodCastList />} />
        {/* PodCastList */}
        <Route
          path="/website-categories"
          element={<ProgrammeCategoriesList />}
        />
        <Route
          path="/website-categories/add-category"
          element={<AddProgrammeCategories />}
        />
        <Route
          path="/website-categories/edit-category/:id"
          element={<EditProgrammeCategories />}
        />
        <Route
          path="/website-general-payment-plans"
          element={<GeneralPaymentPlansList />}
        />
        <Route
          path="/website-general-payment-plans/add-payment-plan/"
          element={<AddGeneralPaymentPlan />}
        />
        <Route
          path="/website-general-payment-plans/edit-payment-plan/:id"
          element={<EditGeneralPaymentPlan />}
        />
        <Route
          path="/website-general-payment-plans/edit-payment-plan/:id"
          element={<EditGeneralPaymentPlan />}
        />
        <Route
          path="/programmes/programm-resources/:id"
          element={<ProgrammeDocumentList />}
        />
        <Route
          path="/programmes/add-programme-resource/:slug"
          element={<AddProgrammeDocument />}
        />
        <Route
          path="/template-pages/socail-sharing-setting/:page_slug"
          element={<SocialSharingCenter />}
        />
        <Route
          path="/programmes/edit-programme-resource/:slug"
          element={<EditProgrammeDocument />}
        />
        {/* programms Doc routes end */}
        {/* program > section */}
        <Route path="/programmes/sections/:slug" element={<SectionList />} />
        {/* program > section end */}
        {/* Programme reviews */}
        <Route
          path="/programmes/reviews/:slug"
          element={<ProgrammeReviews />}
        />
        <Route
          path="/programmes/add-reviews/:slug"
          element={<AddProgrammeReview />}
        />
        <Route
          path="/programmes/edit-review/:slug"
          element={<EditProgrammeReview />}
        />
        {/* Programme reviews end*/}
        {/* program section  */}
        <Route
          path="/programmes/section/:slug"
          element={<ProgrammeSectionList />}
        />
        <Route
          path="/programmes/locked-programme/:slug"
          element={<LockedProgram />}
        />
        <Route
          path="/programmes/add-section/:slug"
          element={<AddProgrammeSection />}
        />
        <Route
          path="/programmes/edit-section/:slug"
          element={<EditProgrammeSection />}
        />
        {/* program section end */}
        {/* dynamite events new  */}
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events"
          element={<GeneralEvents />}
        />
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events/add-event"
          element={<AddOrUpdateGeneralEvents />}
        />
        <Route
          path="/dynamite-events/general-events/edit-event/:event_slug"
          element={<AddOrUpdateGeneralEvents />}
        />
        <Route
          path="/dynamite-events/:event_id/add-members"
          element={<AddDynamiteEventMember />}
        />
        <Route
          path="/dynamite-events/:event_id/edit-member/:member_id"
          element={<EditDynamiteEventMember />}
        />
        {/* ProgrammeAutomatedGroups */}
        <Route
          path="/programmes/automated-group/:slug"
          element={<ProgrammeAutomatedGroups />}
        />
        <Route
          path="/programmes/add-automated-group/:slug"
          element={<AddProgrammeAutoGroup />}
        />
        <Route
          path="/programmes/edit-automated-group/:slug"
          element={<EditProgrammeAutoGroup />}
        />
        {/* ProgrammeAutomatedGroups end  */}
        {/* Member */}
        <Route path="/member" element={<MembersList />} />
        <Route path="/member/import-member" element={<ImportMembers />} />
        <Route
          path="/member/List-forms/:id/form/:slug"
          element={<DetailsFormInformation />}
        />
        <Route path="/member/List-forms/:id" element={<ListUserForm />} />
        <Route path="/member/add-member" element={<AddMember />} />
        <Route path="/member/edit-member/:id" element={<EditMember />} />
        <Route path="/member/member-profile/:id" element={<MemberProfile />} />
        <Route
          path="/member/member-points/:id"
          element={<RecentPointsTable />}
        />
        <Route
          path="/member/:id/member-MemberCalendar/:CalId"
          element={<MemberCalendar />}
        />
        <Route path="/member/member-history/:id" element={<MemoryHistory />} />
        <Route
          path="/member/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/consultant/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/member/member-subscribers/:member_id"
          element={<MemberSubscriptions />}
        />
        <Route
          path="/member/member-personal-notes/:id"
          element={<MemberPersonalNote />}
        />
        <Route
          path="/member/member-goal-statement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member/goal-statement-question-reply/:id"
          element={<QuestionsReply />}
        />
        <Route
          path="/member/event-subscriber/:id"
          element={<EventSubscribers />}
        />
        <Route
          path="/member/goal-statement-Question-history/:id"
          element={<AnswerHistory />}
        />
        <Route
          path="/member/programme-access/:id"
          element={<ManageProgrammeAccess />}
        />
        <Route
          path="/member/reward-access/:id"
          element={<ManageRewardAccess />}
        />
        <Route
          path="/member/add-payment-request/:id"
          element={<AddPaymentRequest />}
        />
        <Route
          path="/member/billing-detail"
          element={<BillingRequestDetail />}
        />
        <Route
          path="/member/all-transaction/:id"
          element={<MemberAllTransaction />}
        />
        <Route
          path="/member/view-billing-list/:id"
          element={<ViewBillingList />}
        />
        <Route
          path="/member/change-password/:id"
          element={<ChangePassword />}
        />
        <Route
          path="/programmes-limited-calendar/:slug"
          element={<DynamicTable />}
        />
        <Route path="/chat" element={<Chat />} />
        <Route path="/member/member-pods" element={<PodsListMemberProfile />} />
        <Route path="/member/to-do-tracker/:id" element={<ToDoTracker />} />
        {/* <Route path="/member/to-do-tracker/:id" element={<ChartJs />} /> */}
        {/* member end  */}
        {/* whats include */}
        <Route
          path="/website-pages/whats-include/:id"
          element={<WhatsIncludeList />}
        />
        <Route
          path="/website-pages/whats-include/add-whats-include/:id"
          element={<AddWhatsInclude />}
        />
        <Route
          path="/website-pages/whats-include/edit-whats-include/:id"
          element={<EditWhatsInclude />}
        />
        {/* consultant   */}
        <Route
          path="/coach/List-forms/:id/form/:slug"
          element={<DetailsFormInformation />}
        />
        <Route path="/coach/List-forms/:id" element={<ListCoachForm />} />
        <Route path="/coach" element={<ConsultantList />} />
        <Route
          path="/consultant/pods-list/:id"
          element={<ConsultantPodsList />}
        />
        <Route
          path="/consultant/member-list/:id"
          element={<MembersListConsultant />}
        />
        <Route path="/coach/add-coach" element={<AddCoach />} />
        <Route
          path="/consultant/member-profile/:id"
          element={<MemberProfile />}
        />
        <Route
          path="/consultant/groups/:id"
          element={<ConsultantGroupsList />}
        />
        <Route
          path="/consultant/goal-statement-list/:id"
          element={<ConsultantGoalStatementList />}
        />
        <Route
          path="/consultant/pods-detail/:id"
          element={<ConsultantPodsDetail />}
        />
        <Route
          path="/consultant/view-group-detail/:id"
          element={<ConsultantGroupDetail />}
        />
        <Route
          path="/consultant/goal-statement-detail/:id"
          element={<ConsultantGoalstatementDetail />}
        />
        <Route
          path="/consultant/recording-detail/:id"
          element={<ConsultantRecordingDetail />}
        />
        <Route path="/coach/edit-coach/:id" element={<EditCoach />} />
        <Route
          path="/coach/change-password/:id"
          element={<ChangeCoachPassword />}
        />
        <Route
          path="/consultant/recordings/:id"
          element={<ConsultantRecordings />}
        />
        {/* consultant end  */}
        {/* /goal-statement start */}
        <Route path="/goal-statement" element={<GoalStatement />} />
        <Route path="/goal-statement/add-question" element={<AddQuestions />} />
        <Route
          path="/goal-statement/detail-question/:id"
          element={<DetailQuestion />}
        />
        <Route
          path="/goal-statement/edit-question/:slug"
          element={<EditQuestions />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddOrUpdateBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddOrUpdateBusinessStrategy />}
        />
        {/* /goal-statement end */}
        {/* /website-pages */}
        <Route path="/website-pages" element={<TemplatePages />} />
        <Route
          path="/website-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route
          path="/website-pages/:page_id/website-faq/add-faq"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="/template-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/template-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/success-schedule"
          element={<SuccessScheduleList />}
        />
        <Route
          path="/template-pages/:page_id/success-schedule"
          element={<SuccessScheduleList />}
        />
        <Route
          path="/template-pages/:page_id/add-success-schedule"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/website-pages/:page_id/add-success-schedule"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/website-pages/:page_id/edit-success-schedule/:success_id"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/template-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route
          path="/template-pages/update-thanks-content/:id"
          element={<UpdateThanksContent />}
        />
        <Route
          path="/template-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        {/* <Route
          path="/website-pages/manage-commission-access/:page_id"
          element={<ManageCommissionAccess />}
        /> */}
        {/* <Route
          path="/website-pages/answers/:created_for_id"
          element={<QuestionsAnswersAsCreatedFor />}
        /> */}
        {/* <Route
          path="/website-pages/answers/:created_for_id/:member_id"
          element={<QuestionsAnswers />}
        /> */}
        <Route
          path="/website-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        {/* <Route
          path="/website-pages/manage-commission-access/:page_id"
          element={<ManageCommissionAccessFromPages />}
        /> */}
        <Route
          path="/website-pages/page-subscriber/:id"
          element={<PageSubscribersList />}
        />
        <Route
          path="/template-pages/page-subscriber/:id"
          element={<PageSubscribersList />}
        />
        <Route path="/website-pages/add-page" element={<AddOrUpdatePage />} />
        <Route
          path="/website-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/website-pages/update-thanks-content/:id"
          element={<UpdateThanksContent />}
        />
        {/* <Route
          path="/website-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        /> */}
        <Route
          path="/website-pages/:page_id/testimonials"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/plan_category/add-plan_category"
          element={<AddOrUpdateWebPagePlanCategory />}
        />
        <Route
          path="/website-pages/:page_id/plan_category/edit-plan_category/:category_id"
          element={<AddOrUpdateWebPagePlanCategory />}
        />
        <Route
          path="/template-pages/:page_id/plan_category/add-plan_category"
          element={<AddOrUpdateWebPagePlanCategory />}
        />
        <Route
          path="/plan_category/add-plan_category"
          element={<AddOrUpdateWebPagePlanCategory />}
        />
        <Route path="/plan_category" element={<WebPagePlanCategory />} />
        <Route
          path="/plan_category/edit-plan_category/:category_id"
          element={<AddOrUpdateWebPagePlanCategory />}
        />
        <Route
          path="/plan_category/manage-plan_category/:category_id"
          element={<WebPageManagePlanCategory />}
        />
        <Route
          path="/template-pages/:page_id/plan_category/edit-plan_category/:category_id"
          element={<AddOrUpdateWebPagePlanCategory />}
        />
        <Route
          path="/template-pages/:page_id/plans_category"
          element={<WebPagePlanCategory />}
        />
        <Route
          path="/website-pages/:page_id/plans_category"
          element={<WebPagePlanCategory />}
        />
        <Route
          path="/website-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route
          path="/template-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route path="/template-pages" element={<TemplatePages />} />
        <Route path="/menus" element={<MenuList />} />
        <Route path="/menu/add-menu" element={<MenuItems />} />
        <Route path="/menu/:id" element={<MenuItems />} />
        <Route
          path="/template-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/testimonials/edit-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/testimonials"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes/add-programme"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes/edit-programme/:programme_slug"
          element={<AddWebsiteProgramme />}
        />
        <Route path="/site-setting" element={<SiteSetting />} />
        <Route
          path="/website-pages/:page_id/testimonials/edit-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route path="/subscription-list" element={<SubscriptionList />} />
        {/* <Route
          path="/website-pages/:page_id/website-faq/add-faq"
          element={<AddOrUpdateWebPageFaq />}
        /> */}
        {/* <Route
          path="website-pages/:page_id/website-faq/edit-faq/:faq_id"
          element={<AddOrUpdateWebPageFaq />}
        /> */}
        {/* <Route
          path="/website-pages/:page_id/website-questions"
          element={<WebPageQuestions />}
        />
        <Route
          path="/website-pages/:page_id/website-questions/add-question"
          element={<AddOrUpdateWebPageQuestion />}
        /> */}
        {/* <Route
          path="/website-pages/:page_id/website-questions/edit-question/:question_id"
          element={<AddOrUpdateWebPageQuestion />}
        /> */}
        {/* <Route
          path="/website-pages/:created_for_id/general-questions"
          element={<GeneralQuestions created_for="page" />}
        /> */}
        {/* <Route
          path="/website-pages/:page_id/payment-plans/"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        /> */}
        <Route
          path="/website-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-program-access"
          element={<ProgrammeAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-program-access"
          element={<ProgrammeAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-events-access"
          element={<EventAccessPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-events-access"
          element={<EventAccessPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />
        <Route
          path="/website-pages/:page_id/questions"
          element={<QuestionSurvey />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/add-programme"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/edit-programme/:programme_slug"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-pages/sale-page/:page_id"
          element={<WebPageEvents />}
        />
        <Route
          path="/website-pages/add-page-event/:page_id"
          element={<AddWebPageEvent />}
        />
        <Route
          path="/website-pages/:page_id/edit-page-event/:id"
          element={<EditWebPageEvent />}
        />
        {/* <Route
          path="/website-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddBusinessStrategy />}
        /> */}
        {/* <Route
          path="/website-pages/:page_id/success-schedule"
          element={<SuccessScheduleList />}
        />
        <Route
          path="/website-pages/:page_id/add-success-schedule"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/website-pages/:page_id/edit-success-schedule/:success_id"
          element={<AddOrUpdateSuccessSchedule />}
        /> */}
        {/* /90-day-questions */}
        <Route path="/90-day-questions" element={<NinetyDayQuestion />} />
        {/* /90-day-questions end */}
        {/* ProgrammeCategories */}
        <Route
          path="/websiteProgrammeCategories"
          element={<ProgrammeCategoriesList />}
        />
        {/* ProgrammeCategories */}
        <Route
          path="/websiteProgrammeCategories"
          element={<ProgrammeCategoriesList />}
        />
        {/* recording /recordings */}
        <Route path="/recording-list" element={<Recordings />} />
        <Route
          path="/recording-list/recording-detail"
          element={<ProgrammeRecordingDetail />}
        />
        <Route
          path="/recording-list/add-recording"
          element={<AddRecordings />}
        />
        <Route
          path="/template-pages/:page_id/banner-slider"
          element={<BannerSliderList />}
        />
        <Route
          path="/template-pages/:page_id/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-pages/:page_id/banner-slider"
          element={<BannerSliderList />}
        />
        <Route
          path="/website-pages/:page_id/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-pages/:page_id/edit-banner-slider/:banner_id"
          element={<AddBannerSlider />}
        />
        <Route
          path="/template-pages/:page_id/edit-banner-slider/:banner_id"
          element={<AddBannerSlider />}
        />
        <Route
          path="/recording-list/edit-recording"
          element={<EditRecording />}
        />
        {/* recording end */}
        {/* vault VaultCategory */}
        <Route path="/categories" element={<VaultCategory />} />
        <Route path="/categories/add-category" element={<AddVaultCategory />} />
        <Route
          path="/categories/edit-category"
          element={<EditVaultCategory />}
        />
        {/* vault end */}
        {/* community */}
        <Route path="/community" element={<CommunityList />} />
        <Route
          path="/community/add-community-reward"
          element={<AddCommunity />}
        />
        <Route
          path="/community/edit-community-reward/:id"
          element={<EditCommunity />}
        />
        <Route path="/series-categories/" element={<SeriesCategoryList />} />
        <Route
          path="/series-categories/add-category"
          element={<AddSeriesCategory />}
        />
        <Route
          path="/series-categories/edit-category/:id"
          element={<EditSeriesCategory />}
        />
        {/* community end */}
        {/* /website-podcasts */}
        <Route path="/website-podcasts" element={<PodcastsList />} />
        <Route path="/website-podcasts/add-podcast" element={<AddPodCast />} />
        <Route
          path="/website-podcasts/edit-podcast/:id"
          element={<EditPodCast />}
        />
        {/* /series-videos */}
        <Route path="/series-videos" element={<SeriesVideosList />} />
        <Route
          path="/series-videos/add-series-videos"
          element={<AddSeriesVideos />}
        />
        <Route
          path="/series-videos/edit-series-videos/:id"
          element={<EditSeriesVideos />}
        />
        {/* feed */}
        <Route path="/community-area" element={<Feed />} />
        <Route path="/feeds" element={<FeedsList />} />
        <Route path="/feeds/add-feed" element={<AddFeeds />} />
        <Route path="/feeds/edit-feed/:id" element={<EditFeeds />} />
        <Route path="/feed/setting" element={<FeedSetting />} />
        <Route path="/pending-posts" element={<PendingPosts />} />
        <Route path="/pending-posts/post-detail" element={<PostDetail />} />
        <Route path="/rejected-posts/post-detail" element={<PostDetail />} />
        <Route path="/post-detail" element={<PostDetail />} />
        <Route
          path="/community-area-post-detail/:id"
          element={<FeedDetailPage />}
        />
        <Route path="/rejected-posts" element={<RejectedPosts />} />
        {/* groups */}
        <Route path="/groups" element={<Groups />} />
        <Route path="/groups/add-group" element={<AddGroups />} />
        <Route path="/groups/edit-group/:slug" element={<EditGroup />} />
        <Route path="/groups/group-members/:slug" element={<GroupMembers />} />
        <Route
          path="/community-area/groups/group-members/:slug"
          element={<GroupMembers />}
        />
        <Route path="/group-feed/:id" element={<GroupsFeedsDetail />} />
        <Route path="/my-posts" element={<MyPosts />} />
        {/* Sessions end */}
        <Route path="/sessions" element={<StudySession />} />
        <Route path="/sessions/add-session" element={<AddStudySession />} />
        <Route path="/sessions/edit-session" element={<EditStudySession />} />
        <Route path="/session-recording" element={<StudySessionRecording />} />
        <Route
          path="/session-recording/add-study-session-recording"
          element={<AddStudySessionRecording />}
        />
        <Route
          path="/session-recording/edit-study-session-recording"
          element={<EditStudySessionRecording />}
        />
        {/* Sessions */}
        {/* QuotesList */}
        <Route path="/quotesList" element={<QuotesList />} />
        <Route path="/quotesList/add-quotes" element={<AddQuotes />} />
        <Route path="/quotesList/edit-quotes" element={<EditQuotes />} />
        {/* QuotesList end */}
        {/* PaymentTransactions */}
        <Route
          path="/payment-transactions"
          element={<PaymentTransactionsList />}
        />
        <Route path="/stripe-products" element={<StripeProductsList />} />
        <Route
          path="/stripe-products/add-product"
          element={<AddStripeProduct />}
        />
        <Route
          path="/stripe-products/edit-product/:id"
          element={<EditStripeProduct />}
        />
        {/* PaymentTransactions end */}
        {/* PaymentTemplateList  */}
        <Route path="/payment-templates" element={<PaymentTemplateList />} />
        <Route
          path="/website-pages/payment-plans/edit-payment-plan/:id"
          element={<EditPaymentPlan />}
        />
        <Route
          path="/payment-template/add-template"
          element={<AddPaymentTemplate />}
        />
        <Route
          path="/payment-template/edit-template/:id"
          element={<EditPaymentTemplate />}
        />
        {/* PaymentTemplateList end  */}
        {/* PaymentRequest   */}
        <Route path="/payment-requests" element={<PaymentRequestList />} />
        <Route
          path="/payment-request/add-payment-request"
          element={<AddPaymentRequests />}
        />
        <Route
          path="/payment-request/payment-request-detail"
          element={<PaymentRequestInfo />}
        />
        <Route
          path="/payment-request/edit-payment-request/:id"
          element={<EditPaymentRequests />}
        />
        {/* PaymentRequest end   */}
        {/* Calendar */}
        <Route
          path="/calendar-groups/group-detail/:id"
          element={<CalendarGroupDetail />}
        />
        {/*Calendar end  */}
        {/*Calendarpods  */}
        <Route path="/calendar-pods" element={<CalendarPodsList />} />
        <Route path="/programmes-calendar/:slug" element={<Calenders />} />
        <Route path="/calendar-pods/add-pod" element={<AddCalendarPods />} />
        <Route
          path="/calendar-pods/edit-pod/:id"
          element={<EditCalendarPods />}
        />
        <Route
          path="/calendar-pods/pods-detail/:id"
          element={<CalendarPodsDetail />}
        />
        <Route
          path="/calendar-pods/add-room-user/:id"
          element={<AddAutomatedRoomUser />}
        />
        <Route
          path="/calendar-pods/edit-room-user/:id"
          element={<EditAutomatedRoomUser />}
        />
        {/*Calendarpods  end*/}
        {/* /general-setting */}
        <Route path="/general-setting" element={<UpdateGeneralSetting />} />
        <Route path="/client-dashboard" element={<ClientDashboardSetting />} />
        <Route path="/invoice-setting" element={<InvoiceSetting />} />
        <Route path="/content-setting" element={<UpdateContentSetting />} />
        <Route path="/website-events" element={<WebPageEvents />} />
        <Route path="/website-events/add-event" element={<AddWebPageEvent />} />
        {/* <Route
          path="/dynamite-events/:event_id/general-events/add-event"
          element={<AddWebPageEvent />}
        /> */}
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events/add-event"
          element={<AddOrUpdateGeneralEvents />}
        />
        <Route
          path="/website-events/edit-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route
          path="/dynamite-events/:event_id/general-events/:id"
          element={<EditWebPageEvent />}
        />
        {/* <Route
          path="/dynamite-events/:dynamite_event_id/general-events"
          element={<WebPageEvents />}
        /> */}
        <Route
          path="/dynamite-events/:event_id/members/:member_id"
          element={<EditDynamiteEventMember />}
        />
        {/* ConsultantGeneralSetting */}
        <Route path="/send-grid" element={<AddSendGrid />} />
        <Route path="/stripe-setting" element={<AddStripeSetting />} />
        <Route path="/email-setting" element={<EmailSetting />} />
        <Route path="/default-setting" element={<DefaultSetting />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/campaign-setting" element={<AddCompaignSetting />} />
        <Route
          path="/email-templates/preview/:id"
          element={<EmailTemplatePreview />}
        />
        <Route
          path="/email-templates/edit-temail-emplate/:id"
          element={<EditEmailTemplate />}
        />
        {/* media section */}
        {/* <Route path="/media-section" element={<MediaSectionList />} /> */}
        <Route path="/media-section" element={<MediaSections />} />
        <Route path="/media-section/add-media" element={<AddMediaSection />} />
        <Route
          path="/media-section/edit-media/:id"
          element={<EditMediaSection />}
        />
        {/* success product */}
        <Route path="/success-product" element={<SuccessProductList />} />
        <Route
          path="/success-product/add-product"
          element={<AddSuccessProduct />}
        />
        <Route
          path="/success-product/edit-product/:id"
          element={<EditSuccessProduct />}
        />
        <Route
          path="/consultants-general-setting"
          element={<UpdateConsultantGeneralSetting />}
        />
        <Route
          path="/programmes/programm_recording_detail/:id"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/goal_statement" element={<GoalStatement />} />
        <Route path="/profile" element={<EditProfile />} />
        <Route path="/pods" element={<Pods />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/memories/memoriesdetails" element={<MemoriesDetails />} />
        <Route path="/daily_activities" element={<DailyActivities />} />
        <Route path="/calender" element={<Calender />} />
        <Route path="/coaches-videos" element={<AdminVideos />} />
        <Route
          path="/coaches_videos_category/coaches-videos/:id"
          element={<AdminVideos />}
        />
        <Route
          path="/coaches_videos_category"
          element={<AdminVideosCatagories />}
        />
        <Route
          path="/members_videos_category"
          element={<AdminVideosCatagories />}
        />
        <Route
          path="/coaches_videos_category/add"
          element={<AddAdminCategory />}
        />
        <Route
          path="/members_videos_category/add"
          element={<AddAdminCategory />}
        />
        <Route
          path="/coaches_videos_category/edit/:id"
          element={<EditAdminVideosCategory />}
        />
        <Route
          path="/members_videos_category/edit/:id"
          element={<EditAdminVideosCategory />}
        />
        <Route
          path="/coaches-videos/add-coach-videos/:id"
          element={<AddAdminVideos />}
        />
        <Route
          path="/coaches-videos/edit-coach-videos"
          element={<EditAdminVideos />}
        />
        <Route
          path="/members-videos/edit-member-videos"
          element={<EditClientVideos />}
        />
        <Route
          path="/coaches-videos/coach-videos-detail/:id"
          element={<AdminVideoDetail />}
        />
        <Route path="/members-videos" element={<ClientVideos />} />
        <Route
          path="/members_videos_category/members-videos/:id"
          element={<ClientVideos />}
        />
        <Route
          path="/members-videos/add-member-videos/:id"
          element={<AddClientVideos />}
        />
        <Route
          path="/members-videos/members-videos-detail/:id"
          element={<ClientVideoDetail />}
        />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        {/* <Route path="404" element={<NotFound />} /> */}
        <Route path="404" element={<Authentication />} />

        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="*" element={<Authentication />} />

        <Route path="/WorkoutDisplay" element={<WorkoutDisplay />} />
      </Route>
    </Routes>
  );
}
